body {
  background-image: linear-gradient(to bottom, #812c46, #594f6e);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.main__div {
  /* height: 100vh; */
  padding-top: 50px;
  padding-bottom: 150px;
}

.container {
  background-color: #fff;
  border-radius: 20px;
  padding-top: 15px;
  /* text-align: center; */
  padding-bottom: 50px;
}

.breakpoint{
  height: 30px;
  width: 40px;
  background-color: #000;
  page-break-before: always;
  page-break-inside: avoid;
}
.btn-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0 20px;
}

.btn {
  text-transform: capitalize;
  width: 90px;
  height: 32px;
  font-weight: 600 !important;
}

.btn--right {
  background-color: #d82975 !important;
}
.btn--left {
  background-color: #d82975 !important;
}

.btn--left-disabled {
  color: #4f4f4f !important;
  background-color: #d9d9d9 !important;
}

.MuiStepIcon-root.Mui-completed,
.Mui-active {
  color: #d82975 !important;
}

.wrapper__Box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 10px auto;
  padding: 0 120px;
}

.wrapper__Box--left {
  text-align: center;
  background-color: #b7daf2;
  padding: 12px 15px;
  border-radius: 5px;
  width: 100%;
}

.MuiMenuItem-root.Mui-selected {
  background-color: lightpink !important;
}

.wrapper__Box--right {
  color: #fff;
  background-color: #096391;
  padding: 12px 15px;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
  margin-left: -15px;
}

.wrapper__Box--accordian {
  color: #fff;
  width: 100%;
  border-radius: 5px;
  margin-left: -15px;
}

.wrapper__Box--accordian .MuiAccordion-root {
  color: #ffffff;
  background-color: #096391;
  margin-left: -9px;
  border-radius: 5px;
  font-weight: bold;
  padding: -5px 0 !important;
}

.wrapper__Box--accordian .MuiCollapse-root {
  background-color: #418ecb;
  color: #fff;
  font-weight: normal;
  margin-left: 0;
}

.Mui-expanded:last-of-type {
  /* margin-left: -9px !important; */
}

.plus-cross--btn {
  margin-left: auto;
  margin-right: 10px;
}

.calculation__text {
  font-weight: 520 !important;
  font-size: 30px !important;
}

.please-note-text {
  text-align: right;
  font-size: 13px;
  right: 0;
}


.please-note-text1 {
  text-align: right;
  margin-top: 60px;
  color: #fff !important;
  font-size: 13px;
}

.grid-container {
  display: inline-grid;
  grid-template-columns: auto auto;
  padding: 10px;
  text-align: center;
}

.grid-item {
  padding: 20px;
  text-align: center;
}

.card {
  width: 300px;
  height: 150px;
}
.card__text {
  font-size: 40px !important;
  color: #d82975;
  font-weight: bold !important;
  font-size: 30px !important;
    padding-left: 15px;
    padding-top: 10px;
}
.card__text2{
  font-size: 40px !important;
  color: #d82975;
  font-weight: bold !important;
    margin-top: -190px;
    margin-bottom: 100px;
    font-size: 40px !important;
    padding-right: 0px;
    padding-top: 15px;
    padding-left: 10px;
}

.card__text--small {
  color: #3f87aa;
}

.snackbar_red .MuiSnackbarContent-root {
  background-color: #d82975;
}

.snackbar_green .MuiSnackbarContent-root {
  background-color: #155e75;
  padding: 22px;
  font-size: 20px;
}

@media screen and (max-width: 900px) {
  .wrapper__Box {
    padding: 0;
  }
  .grid-container {
    grid-template-columns: auto;
  }

  .card__text {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 600px) {
  .main__div {
    padding: 0;
  }
  .container {
    border-radius: 0;
  }
}

@media screen and (max-width: 430px) {
  .btn-wrapper {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .btn--left,
  .btn--left-disabled {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 360px) {
  .card {
    width: 200px;
  }
}

/* .MuiNativeSelect-select option {
  box-shadow: 0 0 10px 100px #fff inset;
  background-color: #812c46 !important;
}
.MuiNativeSelect-select option:checked,
.MuiNativeSelect-select option:hover {
  background-color: #812c46 !important;
  box-shadow: 0 0 10px 100px #fff inset;
}

.MuiNativeSelect-select option:hover {
  background-color: yellow;
} */


.calculatedfor{
  background-color: #03688f;
  color: #fff;
  display: inline-block;
  padding:14px 126px 14px 27px;
  border-radius: 10px;
  margin-right: 20px;
  text-align: left;
}
.usernameimage{
  width: 50px;
  display: inline-block;
  border-radius: 28px;
  margin-right: 20px;
  margin-bottom: -9px;
}
.usernametext{
  display: inline-block;
}
.maindivcal{
  text-align: center;
  margin-top: 60px;
}
.unititle{
  font-size: 32px;
  text-align: center;
  margin-top: 70px;
  color: #03688F;
}
.titletxt{
  text-align: center;
    color: #4F4F4F;
    font-size: 23px;
    font-weight: 500;
}
.Rectangle38{
  position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded{
  margin: 0 !important;
}